import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
    {
        path: '/',
        redirect: { name: 'Dashboard' }
    },
    {
        path: "/home",
        name: "home",
        component: () => import (
            "../views/Manager/Home.vue"),
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                meta: {
                    title: '首页'
                },
                component: () => import ("../views/Manager/Dashboard.vue")
            },
            {
                path: "/cases",
                name: "cases",
                meta: {
                    title: '内容管理'
                },
                component: () => import (
                    "../views/Manager/Cases")
            },
            // {
            //     path: "/ai",
            //     name: "AI",
            //     meta: {
            //         title: 'AI'
            //     },
            //     component: () => import ("../views/Manager/AI")
            // },
            {
                path: "/item",
                name: "Item",
                meta: {
                    title: '项目管理'
                },
                component: () => import ("../views/Manager/Item")
            },
            {
                path: "/itemStatistics",
                name: "ItemStatistics",
                meta: {
                    title: '项目统计'
                },
                component: () => import ("../views/Manager/ItemStatistics")
            },
            {
                path: "/product",
                name: "Product",
                meta: {
                    title: '产品管理'
                },
                component: () => import ("../views/Manager/Product")
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
        /* webpackChunkName: "login" */
        "../views/Manager/Login.vue")
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});
// 未登录则跳转到首页
router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | 三阅后台管理系统`;
    const role = localStorage.getItem('token');
    const time = localStorage.getItem('tokentime')
        console.log(new Date().getTime()-time)
    if (!role && to.path !== '/login') {
        next('/login');
    } else if (to.path !== '/login' && new Date().getTime()-time>27000000){
        next('/login');
    }else{
        next();
    }
});

export default router;
